import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import { Login, Logout, Person, ViewList } from "@mui/icons-material";
import React, { useState } from "react";
import {
  clearAccessToken,
  clearIdToken,
  setAccessToken,
  setIdToken,
  validateUserAccess,
} from "../shared/tokenService.ts";

import { appconfig } from "../config/appconfig.tsx";
import logo from "../img/logo.png";

export default function AppHeader(props) {
  const [anchorEl, setAnchorEl] = useState(null); // (React.useState < null) | (HTMLElement > null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const login = () => {
    props.instance
      .loginPopup(appconfig.scopes)
      .then(async (res) => {
        handleClose();
        if (
          await validateUserAccess(res.accessToken, appconfig.requiredAccessGroup)
        ) {
          props.instance.setActiveAccount(res.account);
          setAccessToken(res.accessToken);
          setIdToken(res.idToken);
          window.location = '/';
        } else {
          window.location = '/forbiden';
        }
      })
      .catch((ex) => {
        console.error(ex);
        return;
      });
  };

  const logout = () => {
    handleClose();
    props.instance.logout().then((res) => {
      props.instance.setActiveAccount(null);
      clearIdToken();
      clearAccessToken();
    });
  };

  return (
    <AppBar position="static" className="app-header">
      <Toolbar variant="secondary" className="mt-0 full-width">
        <IconButton href="/" className="mr-20">
          <img src={logo} className="title-logo" alt="Support portal" />
        </IconButton>

        <Button href="/logs" variant="outlined">
          <ViewList className="mr-3" />
          Logs
        </Button>
        <Stack className="toolbar-spacer"></Stack>
        <div>
          <Button
            // variant="primary"
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            {props.activeUser?.name || "Menu"}
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
              top: "10px",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
              top: "200px",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {props.isAuthenticated ? (
              <Stack style={{ top: "100px" }}>
                <Button
                  variant="outlined"
                  href="/profile"
                  onClick={handleClose}
                >
                  <Person />
                  Profile
                </Button>

                <Button onClick={logout}>
                  <Logout />
                  Logout
                </Button>
              </Stack>
            ) : (
              <Button onClick={login}>
                <Login />
                Login
              </Button>
            )}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
