import { IPublicClientApplication } from "@azure/msal-browser";
import { appconfig } from "../config/appconfig.tsx";
import { jwtDecode } from "jwt-decode";

const ID_TOKEN = "idToken";
const ACCESS_TOKEN = "accessToken";

const refreshToken = async (instance: IPublicClientApplication) => {
  try {
    const response = await instance?.acquireTokenPopup({
      scopes: appconfig.scopes,
    });
    setAccessToken(response.accessToken);
    setIdToken(response.idToken);
    return { accessToken: response.accessToken, idToken: response.idToken };
  } catch (ex) {
    console.error(ex);
    return { accessToken: null, idToken: null };
  }
};

const isTokenExpired = (token: string) => {
  const decoded = jwtDecode(token!);
  const now = Date.now();
  console.log(
    `Token expire in: ${((decoded.exp! * 1000 - now) / 1000).toFixed(2)} sec`
  );
  if (!token || decoded.exp! * 1000 < now) {
    return true;
  } else {
    return false;
  }
};

export const getIdToken = (instance: IPublicClientApplication) => {
  let userToken = localStorage.getItem(ID_TOKEN);
  if (isTokenExpired(userToken!)) {
    refreshToken(instance).then((res) => {
      const { idToken } = res;
      userToken = idToken;
      return idToken;
    });
  }
  return userToken;
};

export const setIdToken = (token: string) => {
  localStorage.setItem(ID_TOKEN, token);
};

export const clearIdToken = () => {
  localStorage.setItem(ID_TOKEN, "");
};

export const getAccessToken = (instance: IPublicClientApplication) => {
  let userToken = localStorage.getItem(ACCESS_TOKEN);
  if (isTokenExpired(userToken!)) {
    refreshToken(instance).then((res) => {
      const { accessToken } = res;
      userToken = accessToken;
      return accessToken;
    });
  }
  return userToken;
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const clearAccessToken = () => {
  localStorage.setItem(ACCESS_TOKEN, "");
  localStorage.setItem(ID_TOKEN, "");
};

export const validateUserAccess = async (
  accessToken: string,
  requiredAccessGroup: string
) => {
  const userGroupsUrl = "https://graph.microsoft.com/v1.0/me/memberOf";
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${accessToken}`);
  const options = {
    method: "GET",
    headers: headers,
  };

  const response = await fetch(userGroupsUrl, options);
  const groups = await response.json();
  const desiredGroup = groups.value.find(x => x.id === requiredAccessGroup);
  if (desiredGroup) {
    return true;
  } else {
    return false;
  }
};
