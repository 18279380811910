import { MessageStatus } from "./messageStatus.enum.ts";

export default class MessageFilterRequest {
  pageSize: number = 50;
  pageNumber: number = 0;
  id?: string;
  eventSource?: string;
  schemaType?: string;
  documentRefNumber?: string;
  statusMessage?: string;
  resolvedManually?: boolean;
  status?: string = MessageStatus.ERROR;
  createdDateTimeGte?: Date;
  createdDateTimeLte?: Date;
  sortByCreatedDateTimeAsc?: boolean = false;
}
