import { Button } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import youShallNotPass from "../img/error_403.png";

export default function ForbidenPage(props) {
  const { instance } = useMsal();
  const logout = () => {
    instance.logout().then((res) => {
      props.instance.setActiveAccount(null);
      clearIdToken();
      clearAccessToken();
      window.location = '/';
    });
  };

  return (
    <div className="container">
      <div className="flex">
        <img className="self-center" src={youShallNotPass} />
        </div>
        <div className="flex">
        <Button variant="outlined" className="ml-a mr-a mt-2" onClick={logout}>
          Logout
        </Button>
        </div>
    </div>
  );
}
