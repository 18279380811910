export default class Message {
  id?: string;
  eventSource?: string;
  eventSubject?: string;
  schemaType?: string;
  createdDateTime?: Date;
  documentRefNumber?: string;
  retryCount?: number;
  status?: string;
  processedByVmsDateTime?: Date;
  statusMessage?: string;
  inQueue?: boolean;
  resolvedManually?: boolean;
}
