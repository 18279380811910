import Message from './message.model';

export default class MessageSearchResponse {
  content?: Message[];
  pageNumber?: number;
  currentSize?: number;
  total?: number;
  totalPages?: number;
  firstPage?: boolean;
  lastPage?: boolean;
}
