export const appconfig = {
  mode: "test",
  requiredAccessGroup: "68cc6ffb-bd0e-4d8b-a326-90ccf51fec7c",
  apiUrl: "https://test.support-portal-backend.vmsak-sky.no/api",
  msalConfig: {
    auth: {
      clientId: `08ebb521-c693-407c-9252-6942349e6ff7`,
      authority: `https://login.microsoftonline.com/6dfcc7f0-98d9-48b6-9251-b8a2bbdbfe37`,
      redirectUri: `https://test.supportportal.vmsak-sky.no`,
      postLogoutRedirectUri: `https://test.supportportal.vmsak-sky.no`
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  scopes: [
    "openid",
    "email",
    "profile",
    "offline_access",
    "User.Read",
    "Mail.Read",
    "Files.Read.All",
    "Files.Read",
    "Files.ReadWrite",
  ],
};
