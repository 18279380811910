import ProfileType from "../models/profileType.model.ts";
import React from "react";
import { getAccessToken } from "../shared/tokenService.ts";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me";

export default class UserProfilePage extends React.Component {
  state = {
    profile: new ProfileType(),
  };

  componentDidMount() {
    this.loadProfile();
  }

  loadProfile = () => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${getAccessToken(this.props.instance)}`);
    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(GRAPH_ENDPOINT, options)
      .then((response) => response.json())
      .then((prof) => this.setState({ profile: prof }))
      .catch((ex) => {
        console.error(ex);
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 ml-20">
          <div><strong>First Name: </strong> {this.state.profile?.givenName}</div>
          <div><strong>Last Name: </strong> {this.state.profile?.surname}</div>
          <div><strong>Email: </strong> {this.state.profile?.userPrincipalName}</div>
          <div><strong>Id: </strong> {this.state.profile?.id}</div>
        </div>
      </div>
    );
  }
}
