import FileInfo from './fileInfo.model.ts';
import Message from './message.model.ts';

export class MessageFullDetails extends Message
{
  ownerPartyId?: string;
  ownerPersonNumber?: string;
  ownerOrganisationNumber?: string;
  ownerUsername?: string;
  appId?: string;
  files?: FileInfo[];
}
