import {
  AttachFile,
  CheckCircle,
  Clear,
  Code,
  Done,
  ExpandLess,
  ExpandMore,
  FileDownload,
  FileDownloadRounded,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Stack,
  Typography
} from "@mui/material";
import React, { useState } from "react";

import { MessageFullDetails } from "../../models/messageDetails.model.ts";
import { MessageStatus } from "../../models/messageStatus.enum.ts";
import { appconfig } from "../../config/appconfig.tsx";
import getFileSize from "../../shared/fileUtils.ts";
import { getIdToken } from "../../shared/tokenService.ts";
import moment from "moment/moment";
import { useMsal } from "@azure/msal-react";

export default function MessageTempate(props) {
  const { instance } = useMsal();
  const [message, setMessage] = useState(new MessageFullDetails());
  const [wasShown, setWasShown] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResloved, setIsResolved] = useState(props.message.resolvedManually);
  
  const [resolvedMessageText] = useState(
    props.message?.resolvedManually === true ? "Resolved manually" : ""
  );

  const loadMessageDetails = () => {
    if (!wasShown) {
      setLoading(true);
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${getIdToken(instance)}`);

      fetch(appconfig.apiUrl + `/messages/${props.message.id}`, {
        headers,
      })
        .then((response) => response.json())
        .then((details) => {
          setMessage(details);
          setWasShown(true);
          setIsExpanded(true);
        })
        .catch((ex) => console.error(ex))
        .finally(() => setLoading(false));
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  const downloadAllFiles = () => {
    if (message?.files) {
      message.files.forEach(async (file) => {
        downloadFile(file.id, file.fileName);
      });
    }
  };

  const downloadFile = (fileId, fileName) => {
    if (!fileId) {
      return;
    }
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${getIdToken(instance)}`);
    headers.append("Content-Type", "application/json");

    fetch(
      appconfig.apiUrl +
        `/messages/${props.message.id}/files/${fileId}/download`,
      { headers, responseType: "blob" }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((ex) => console.error(ex));
  };

  const markResolved = (isResolved) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${getIdToken(instance)}`);
    headers.append("Content-Type", "application/json");

    fetch(appconfig.apiUrl + `/messages/${props.message.id}`, {
      headers,
      method: "PATCH",
      body: JSON.stringify({
        resolvedManually: isResolved,
      }),
    }).then((result) => {
      if (message) {
        setIsResolved(isResolved);
      }
    });
  };

  return (
    <>
      <Card variant="outlined" className="mb-2">
        <CardHeader
          title={
            <>
              <div className="message__title row mb-2">
                <div className="col-6">
                  Schema: <strong>{props.message?.schemaType}</strong>
                </div>
                <div className="col-3">
                  Ref number:{" "}
                  <strong>{props.message?.documentRefNumber}</strong>
                </div>
                <div className="col-3 align align--right">
                  Status:&nbsp;
                  <strong
                    className={`
                    ${
                      props.message?.status === MessageStatus.ERROR
                        ? "message--status-error "
                        : ""
                    }
                    ${
                      props.message?.status === MessageStatus.ERROR &&
                      props.message?.inQueue === true
                        ? "message--status-inqueue "
                        : ""
                    }
                    ${
                      props.message?.status === MessageStatus.OK
                        ? "message--status-info"
                        : ""
                    }`}
                  >
                    {props.message.status}
                    {props.message.inQueue && <label> - in queue</label>}
                    {isResloved && <span>&nbsp;- Resolved manually</span>}
                  </strong>
                </div>
              </div>
            </>
          }
          subheader={
            <>
              <div className={`message__subtitle  ${
                  !isExpanded ? `message__subtitle--collapsed` : ``
                }`}
                title={props.message?.statusMessage}
              >
                {props.message?.statusMessage}
              </div>
            </>
          }
        ></CardHeader>
        {isExpanded && (
          <CardContent>
            <div className="row message__details mt-15">
              <div className="col-12">
                <p>
                  <label>Source:</label>
                  <strong>{message?.eventSource}</strong>
                </p>
              </div>
              <div className="col-4">
                <p>
                  <label>Event Id:</label>
                  <strong>{message?.id}</strong>
                </p>
                <p>
                  <label>Application Id:</label>
                  <strong>{message?.appId}</strong>
                </p>
                <p>
                  <label>Created date:</label>
                  <strong>
                    {moment(message?.createdDateTime).format("L")}
                  </strong>
                </p>
                <p>
                  <label>Status:</label>
                  <strong>
                    {message?.status} {resolvedMessageText}
                  </strong>
                </p>
              </div>
              <div className="col-4">
                <p>
                  <label>Event subject:</label>
                  <strong>{message?.eventSubject}</strong>
                </p>
                <p>
                  <label>Schema type:</label>
                  <strong>{message?.schemaType}</strong>
                </p>
                <p>
                  <label>Retry count:</label>
                  <strong>{message?.retryCount}</strong>
                </p>
                <p>
                  <label>Processed by VMS time:</label>
                  <strong>
                    {moment(message?.processedByVmsDateTime).format("L")}
                  </strong>
                </p>
              </div>
              <div className="col-4">
                <p>
                  <label>Owner person number:</label>
                  <strong>{message?.ownerPersonNumber}</strong>
                </p>
                <p>
                  <label>Owner party Id:</label>
                  <strong>{message?.ownerPartyId}</strong>
                </p>
                <p>
                  <label>Owner organisation no:</label>
                  <strong>{message?.ownerOrganisationNumber}</strong>
                </p>
                <p>
                  <label>Owner username:</label>
                  <strong>{message?.ownerUsername}</strong>
                </p>
              </div>
            </div>
            <div className="table message__table mt-50 p-10">
              <div className="row table__header">
                <div className="col-3">File name</div>
                <div className="col-2">Data type</div>
                <div className="col-1">Content type</div>
                <div className="col-2">Created</div>
                <div className="col-2">Tags</div>
                <div className="col-1">Size</div>
                <div className="col-1">Actions</div>
              </div>
              {message?.files?.map((element) => {
                const isXml = element.contentType === "application/xml";
                return (
                  <div key={element.id} className="row table__data">
                    <div
                      className="col-3 table__data--fileName"
                      title={element.fileName}
                    >
                      <span className="message__icon">{isXml ? <Code /> : <AttachFile />}</span>
                      {element.fileName}
                    </div>
                    <div className="col-2 text-center" title={element.dataType}>
                      {element.dataType}
                    </div>
                    <div
                      className="col-1 text-center"
                      title={element.contentType}
                    >
                      {element.contentType}
                    </div>
                    <div className="col-2 text-center" title={element.created}>
                      {moment(element.created).format("L")}
                    </div>
                    <div className="col-2" title={element.tags}>
                      {element.tags?.join(",")}
                    </div>
                    <div className="col-1 text-center">
                      {getFileSize(element?.size)}
                    </div>
                    <div className="col-1 flex center">
                      <Button
                        className="message__icon"
                        size="small"
                        onClick={() =>
                          downloadFile(element?.id, element?.fileName)
                        }
                      >
                        <FileDownload />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </CardContent>
        )}
        <Divider variant="inset"/>
        <CardActions>
          
          <div className="toolbar-spacer"></div>
          <Stack direction="row" spacing={{ xl: 1 }}>
            {message.files && isExpanded && (
              <Button
                size="small"
                variant="outlined"
                onClick={async () => downloadAllFiles()}
              >
                <FileDownloadRounded /> Download all
              </Button>
            )}
            {props.message.status === MessageStatus.ERROR &&
              !props.message.inQueue && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => markResolved(!isResloved)}
                >
                  {!isResloved ? <><Done />Resolve</> : <><Clear />Unresolve</>}
                </Button>
              )}
            <Button
              size="small"
              variant="outlined"
              onClick={() => loadMessageDetails()}
            >
              {!isExpanded ? <ExpandMore /> : <ExpandLess />}
            </Button>
          </Stack>
        </CardActions>
        {loading && <LinearProgress className="indeterminate" />}
      </Card>
    </>
  );
}
