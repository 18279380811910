import "moment/locale/nb";
import "./styles/App.scss";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { appconfig } from "./config/appconfig.tsx";
import moment from "moment";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(appconfig.msalConfig);
console.log(appconfig);
console.log(process.env);
moment.locale('nb');

root.render(
  <MsalProvider instance={msalInstance}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <App />
      </Router>
    </LocalizationProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
