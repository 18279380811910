import { Button, FormGroup, Switch, TextField } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import MessageFilterRequest from "../models/messageFilterRequest.model.ts";
import { MessageStatus } from "../models/messageStatus.enum.ts";
import React from "react";

export default class SearchComponent extends React.Component {
  state = {
    searchRequest: new MessageFilterRequest(),
  };

  componentDidMount() {
    const now = new Date();
    this.setState({ searchRequest: this.props.searchRequest });
    if (!this.state.searchRequest.createdDateTimeGte) {
      this.setState((prevState) => ({
        searchRequest: {
          ...prevState.searchRequest,
          createdDateTimeGte: now.setHours(0, 0, 0, 0),
        },
      }));
    }
    if (!this.state.searchRequest.createdDateTimeLte) {
      this.setState((prevState) => ({
        searchRequest: {
          ...prevState.searchRequest,
          createdDateTimeLte: now.setHours(23, 59, 59, 0),
        },
      }));
    }
  }

  updateSearchRequest = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    switch (type) {
      case "checkbox":
        switch (name) {
          case "status":
            const errorsOnly = checked ? MessageStatus.ERROR : undefined;
            this.setState((prevState) => ({
              searchRequest: {
                ...prevState.searchRequest,
                [name]: errorsOnly,
              },
            }));
            break;
          default:
            // searchRequest[name] = checked ? checked : undefined;
            this.setState((prevState) => ({
              searchRequest: {
                ...prevState.searchRequest,
                [name]: checked ? checked : undefined,
              },
            }));
        }
        break;
      case "date":
        // searchRequest[name] = moment(value).format(dateFormat);
        this.setState((prevState) => ({
          searchRequest: {
            ...prevState.searchRequest,
            [name]: value,
          },
        }));
        break;
      default:
        // searchRequest[name] = value;
        this.setState((prevState) => ({
          searchRequest: {
            ...prevState.searchRequest,
            [name]: value,
          },
        }));
    }
  };

  render() {
    return (
      <> 
       <div className="pad-10 row">
          <div className="col-3">
            <TextField
              label="Schema"
              name="schemaType"
              value={this.state.searchRequest.schemaType}
              variant="outlined"
              size="small"
              fullWidth
              onChange={this.updateSearchRequest}
            />
          </div>
          <div className="col-3">
            <TextField
              label="Altinn reference number"
              name="documentRefNumber"
              value={this.state.searchRequest.documentRefNumber}
              variant="outlined"
              size="small"
              fullWidth
              onChange={this.updateSearchRequest}
            />
          </div>
          <div className="col-3">
            <TextField
              label="Event id"
              value={this.state.searchRequest.id}
              name="id"
              variant="outlined"
              size="small"
              fullWidth
              onChange={this.updateSearchRequest}
            />
          </div>
          <div className="col-3">
            <TextField
              label="Response"
              value={this.state.searchRequest.statusMessage}
              name="statusMessage"
              variant="outlined"
              size="small"
              fullWidth
              onChange={this.updateSearchRequest}
            />
          </div>
        </div>
        <div className="pad-10 row">
          <div className="col-3">
            <DatePicker
              slotProps={{ textField: { fullWidth: true, size: "small" } }}
              label="Date from"
              value={this.state.searchRequest.createdDateTimeGte ?? new Date()}
              format="dd.MM.yyyy"
              name="createdDateTimeGte"
              onChange={(e) =>
                this.updateSearchRequest({
                  target: {
                    name: "createdDateTimeGte",
                    value: e,
                    type: "date",
                    checked: undefined,
                  },
                })
              }
            />
          </div>
          <div className="col-3">
            <DatePicker
              slotProps={{ textField: { fullWidth: true, size: "small" } }}
              label="Date to"
              value={this.state.searchRequest.createdDateTimeLte ?? new Date()}
              format="dd.MM.yyyy"
              name="createdDateTimeLte"
              onChange={(e) =>
                this.updateSearchRequest({
                  target: {
                    name: "createdDateTimeLte",
                    value: e,
                    type: "date",
                    checked: undefined,
                  },
                })
              }
            />
          </div>
          <div className="col-3">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={this.state.searchRequest.resolvedManually ?? false}
                    name="resolvedManually"
                    onChange={this.updateSearchRequest}
                  />
                }
                label="Resolved manually"
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="status"
                    checked={
                      this.state.searchRequest.status === MessageStatus.ERROR ??
                      false
                    }
                    onChange={this.updateSearchRequest}
                  />
                }
                label="Only errors"
              />
            </FormGroup>
          </div>
          <div className="col-3">
            <Button
              className="float-end mr-2"
              size="small"
              onClick={() =>
                this.props.onSearchClicked(this.state.searchRequest)
              }
              variant="outlined"
            >
              Search
            </Button>
          </div>
        </div>
      </>
    );
  }
}
