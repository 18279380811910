import React, { useEffect, useState } from "react";

import MessageFilterRequest from "../models/messageFilterRequest.model.ts";
import MessageSearchResponse from "../models/messageSearchResponse.model.ts";
import MessageTempate from "./templates/MessageTemplate";
import SearchComponent from "./SearchComponent";
import { Spinner } from "react-awesome-spinners";
import { appconfig } from "../config/appconfig.tsx";
import { dateFormat } from "../shared/appConstants.ts";
import { getIdToken } from "../shared/tokenService.ts";
import moment from "moment";
import { useMsal } from "@azure/msal-react";

export default function LogsPage(props) {
  const { instance } = useMsal();
  const [searchResponse, setSearchResponse] = useState(
    new MessageSearchResponse()
  );
  const [loading, setLoading] = useState(false);

  const [searchRequest, setSearchRequest] = useState(
    new MessageFilterRequest()
  );
  useEffect(() => {
    const now = new Date();
    setSearchRequest({
      createdDateTimeGte: moment(now.setHours(0, 0, 0, 0)).format(dateFormat),
      createdDateTimeLte: moment(now.setHours(23, 59, 59, 0)).format(
        dateFormat
      ),
    });
  }, []);

  const getMessages = async (searchRequest) => {
    setLoading(true);
    let queryParams = new URLSearchParams();
    Object.keys(searchRequest).forEach((key) => {
      let value = searchRequest[key];
      if (value !== null && value !== undefined && value !== "") {
        if (key.indexOf("createdDateTime") > -1) {
          value = moment(value).format(dateFormat);
        }
        queryParams.append(key, `${value}`);
      }
    });

    let query = "";
    if (queryParams) {
      query = `?${queryParams}`;
    }

    const headers = new Headers();
    const token = getIdToken(instance);
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const searchResponse = await (
        await fetch(appconfig.apiUrl + `/messages${query}`, { headers })
      ).json();
      setSearchResponse(searchResponse);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  };

  return (
    <>
      {props.instance?.getActiveAccount() && (
        <>
          <div className="logs logs__search">
            <SearchComponent
              onSearchClicked={getMessages}
              searchRequest={searchRequest}
            />
          </div>
          <div className="logs logs__results">
            {!loading &&
              searchResponse?.content?.map((message, index) => {
                return <MessageTempate key={message.id} message={message} />;
              })}
            {loading && (
              <div className="logs__loader flex center">
                <Spinner />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
