import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";

import AppHeader from "./components/AppHeader";
import ForbidenPage from "./components/ForbidenPage";
import LogsPage from "./components/LogsPage";
import React from "react";
import UserProfilePage from "./components/UserProfilePage";

export default function App() {
  const { instance } = useMsal();

  return (
    <>
      {window.location.pathname != "/forbiden" && (
        <AppHeader
          instance={instance}
          isAuthenticated={instance?.getActiveAccount() != null}
          activeUser={instance?.getActiveAccount()}
        />
      )}

      <div className="container full-width">
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<LogsPage instance={instance} />}></Route>
            <Route
              path="/logs"
              element={<LogsPage instance={instance} />}
            ></Route>
            <Route
              path="/profile"
              element={<UserProfilePage instance={instance} />}
            ></Route>
          </Routes>
        </AuthenticatedTemplate>
      </div>
      <Routes>
        <Route path="/forbiden" element={<ForbidenPage />} />
      </Routes>
    </>
  );
}
